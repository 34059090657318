export enum ActionTypes {
  LOADING = '@avatar/LOADING',
  ERROR = '@avatar/ERROR',
  SET_BRIDGE_ORDER_LIST = '@avatar/SET_BRIDGE_ORDER_LIST',
}

export interface BridgeOrderStateProps {
  itemsList: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_BRIDGE_ORDER_LIST;
      payload: any;
    };

const bridgeOrderListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: bridgeOrderListState,
  isLoading: false,
  isError: false,
};

export const bridgeOrderReducer = (state: BridgeOrderStateProps = initialState, action: Action): BridgeOrderStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_BRIDGE_ORDER_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    default:
      return state;
  }
};
