import moment from 'moment/moment';
import { GridColDef } from '@mui/x-data-grid';

import { secondsToTime } from '@/shared/utils/secondsToTime';
import {
  FactionOptions,
  knowledgeBaseRequestStatusOptions,
  LocationOptions, Status, SubscriptionPlan,
  SubscriptionServiceType
} from '@/data/constants';

import { RowActions } from '../RowActions';
import { RowUserActions } from '../RowUserActions';
import { RowBecomeCreatorActions } from '../RowBecomeCreatorActions';
import { RowAvatarActions } from '../RowAvatarActions';
import { RowInstanceActions } from '../RowInstanceActions';
import { RowEventRolesActions } from '../RowEventRolesActions';
import { RowActionEdit } from '../RowActionEdit';
import { RowPoolActions } from '../RowPoolActions';
import { RowAssistantActions } from '../RowAssistantActions';

import styles from './TableBody.module.scss';
import { applyDecimals, removeDecimals } from "@/shared/utils/decimals";

export const columns = () => {
  const venue: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        if (params.row.status === Status.DRAFT) {
          return <span className={styles.neutral}>draft</span>;
        } else {
          return <span>{params.row.status.toLowerCase()}</span>;
        }
      },
    },
    {
      field: 'lifecycleStatus',
      headerName: 'Lifecycle status',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        if (params.row.lifecycleStatus === 'draft') {
          return <span className={styles.negative}>draft</span>;
        }
      },
    },
    {
      field: 'faction',
      headerName: 'Faction',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return FactionOptions.find(faction => faction.value === params.row.faction)?.label;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return LocationOptions.find(location => location.value === params.row.location)?.label;
      },
    },
    {
      field: 'isDeleted',
      headerName: 'IsDeleted',
      editable: false,
      hideSortIcons: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        if (!params.row.isDeleted) {
          return 'No';
        }
        return <span className={styles.negative}>Yes</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'updatedAt',
      headerName: 'Update date',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.updatedAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowActions
          itemId={params.row.id}
          ownerId={params.row.ownerId}
          isMinted={params.row.lifecycleStatus === 'minted'}
          isDeleted={params.row.isDeleted}
          pool={params.row.pool}
        />
      ),
    },
  ];

  const event: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Event name',
      flex: 1,
      minWidth: 220,
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'venueName',
      headerName: 'Venue Name',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'startAt',
      headerName: 'Start',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => moment(params.row.startAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'finishAt',
      headerName: 'Finished',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => moment(params.row.finishAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'adminStatus',
      headerName: 'Admin Status',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'clientStatus',
      headerName: 'Client Status',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'lifecycleStatus',
      headerName: 'Lifecycle Status',
      editable: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        if (params.row.lifecycleStatus === 'draft') {
          return <span className={styles.negative}>draft</span>;
        }
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowActions
          itemId={params.row.id}
          isMinted={params.row.lifecycleStatus === 'minted'}
        />
      ),
    },
  ];

  const eventRole: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Role id',
      flex: 1,
      minWidth: 240,
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: 'Event role',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      editable: false,
      hideSortIcons: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => moment(params.row.startAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      editable: false,
      hideSortIcons: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => moment(params.row.finishAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'isDeleted',
      headerName: 'IsDeleted',
      editable: false,
      hideSortIcons: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        if (!params.row.isDeleted) {
          return 'No';
        }
        return <span className={styles.negative}>Yes</span>;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => <RowEventRolesActions roleId={params.row.id} />,
    },
  ];

  const user: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'username',
      headerName: 'User Name',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'role',
      headerName: 'User Role',
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const roles = params.row.roles
          .map((item: any, index: number) => {
            return item.role.name.toLowerCase();
          })
          .join(', ');
        return <span className={styles.custom__sell}>{roles}</span>;
      },
    },
    {
      field: 'walletAddress',
      headerName: 'Wallet address',
      flex: 1,
      minWidth: 200,
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'paymentPlan',
      headerName: 'Payment Plan',
      flex: 1,
      minWidth: 90,
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <span className={styles.custom__sell}>{SubscriptionPlan[params.row.subscriptions[0]?.plan]}</span>;
      }
    },
    {
      field: 'paymentType',
      headerName: 'Payment Method',
      flex: 1,
      minWidth: 150,
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <span className={styles.custom__sell}>{SubscriptionServiceType[params.row.subscriptions[0]?.type]}</span>;
      }
    },
    {
      field: 'faction',
      headerName: 'Faction',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 80,
      renderCell: (params) => {
        return FactionOptions.find(faction => faction.value === params.row.faction)?.label;
      },
    },
    {
      field: 'isActivated',
      headerName: 'IsActivated',
      flex: 1,
      minWidth: 70,
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isActivated) {
          return 'Yes';
        }
        return <span className={styles.negative}>No</span>;
      },
    },
    {
      field: 'isBanned',
      headerName: 'IsBanned',
      flex: 1,
      minWidth: 70,
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (!params.row.isBanned) {
          return 'No';
        }
        return <span className={styles.negative}>Yes</span>;
      },
    },
    {
      field: 'isBlocked',
      headerName: 'IsBlocked',
      flex: 1,
      minWidth: 70,
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (!params.row.isBlocked) {
          return 'No';
        }
        return <span className={styles.negative}>Yes</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      flex: 1,
      minWidth: 146,
      editable: false,
      // hideSortIcons: true,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowUserActions
          userId={params.row.id}
          isActivated={params.row.isActivated}
          isBanned={params.row.isBanned}
        />
      ),
    },
  ];

  const creator: GridColDef[] = [
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'username',
      headerName: 'User Name',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'role',
      headerName: 'User Role',
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const roles = params.row.roles
          .map((item: any, index: number) => {
            return item.role.name.toLowerCase();
          })
          .join(', ');
        return <span className={styles.custom__sell}>{roles}</span>;
      },
    },
    {
      field: 'walletAddress',
      headerName: 'Wallet address',
      flex: 1,
      minWidth: 220,
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'isActivated',
      headerName: 'IsActivated',
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isActivated) {
          return 'Yes';
        }
        return <span className={styles.negative}>No</span>;
      },
    },
    {
      field: 'isBanned',
      headerName: 'IsBanned',
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (!params.row.isBanned) {
          return 'No';
        }
        return <span className={styles.negative}>Yes</span>;
      },
    },
    {
      field: 'isBlocked',
      headerName: 'IsBlocked',
      flex: 1,
      minWidth: 80,
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (!params.row.isBlocked) {
          return 'No';
        }
        return <span className={styles.negative}>Yes</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      flex: 1,
      minWidth: 146,
      editable: false,
      // hideSortIcons: true,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowUserActions
          userId={params.row.id}
          isBanned={params.row.isBanned}
        />
      ),
    },
  ];

  const becomeCreator: GridColDef[] = [
    {
      field: 'username',
      headerName: 'User Name',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      flex: 1,
      minWidth: 146,
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'updatedAt',
      headerName: 'Update date',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.updatedAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowBecomeCreatorActions
          userId={params.row.user.id}
          roleId={params.row.role.id}
          requestId={params.row.id}
          status={params.row.status}
        />
      ),
    },
  ];

  const instance: GridColDef[] = [
    {
      field: 'slots',
      headerName: 'Slots',
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'venueName',
      headerName: 'Venue Name',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'selfAddressUrl',
      headerName: 'Self address URL',
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'voipStream',
      headerName: 'Voip stream',
      flex: 1,
      minWidth: 220,
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      flex: 1,
      minWidth: 146,
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => <RowInstanceActions itemId={params.row.id} />,
    },
  ];

  const avatar: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      // hideSortIcons: true,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'isDefault',
      headerName: 'Default',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'lifecycleStatus',
      headerName: 'Lifecycle status',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        if (params.row.lifecycleStatus === 'draft') {
          return <span className={styles.negative}>draft</span>;
        }
      },
    },
    {
      field: 'ownerId',
      headerName: 'Owner',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <span className="MuiDataGrid-cellContent">{params.row.ownerName}</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      flex: 1,
      minWidth: 146,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowAvatarActions
          itemId={params.row.id}
          isMinted={params.row.lifecycleStatus !== 'draft'}
        />
      ),
    },
  ];

  const customAvatar: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      editable: false,
      sortable: false,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'isActive',
      headerName: 'isActive',
      flex: 1,
      minWidth: 146,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (params.row.isActive) {
          return 'Yes';
        }
        return <span className={styles.negative}>No</span>;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      flex: 1,
      minWidth: 146,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowActions
          isMinted={false}
          itemId={params.row.id}
          customAvatar={params.row}
        />
      ),
    },
  ];

  const assistant: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Assistant ID',
      editable: false,
      // hideSortIcons: true,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'avatarId',
      headerName: 'Avatar ID',
      editable: false,
      // hideSortIcons: true,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'knowledgeBaseMap.status',
      headerName: 'Status',
      editable: false,
      // hideSortIcons: true,
      sortable: false,
      filterable: true,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const status = knowledgeBaseRequestStatusOptions.find(kb => kb.value === params.row.knowledgeBaseMap.status);
        return <span>{status?.label}</span>;
      },
    },
    // {
    //   field: 'isActive',
    //   headerName: 'Active',
    //   editable: false,
    //   sortable: true,
    //   filterable: false,
    //   hideable: false,
    //   disableColumnMenu: true,
    //   flex: 1,
    //   minWidth: 150,
    //   renderCell: (params) => {
    //     if (params.row.isActive) {
    //       return 'Yes';
    //     }
    //     return <span className={styles.negative}>No</span>;
    //   },
    // },
    {
      field: 'interactions',
      headerName: 'Interactions',
      editable: false,
      // hideSortIcons: true,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      flex: 1,
      minWidth: 146,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowAssistantActions
          itemId={params.row.id}
        />
      ),
    },
  ];

  const proposal: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'creatorId',
      headerName: 'Creator',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <span className="MuiDataGrid-cellContent">{params.row.userName}</span>;
      },
    },
    {
      field: 'votingEndsAt',
      headerName: 'Voting ends at',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => moment(params.row.votingEndsAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'lifecycleStatus',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'faction',
      headerName: 'Faction',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return FactionOptions.find(faction => faction.value === params.row.faction)?.label;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      editable: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return LocationOptions.find(location => location.value === params.row.location)?.label;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowActionEdit
          itemId={params.row.id}
          isEditable={params.row.lifecycleStatus !== 'CROWDFUNDING' && params.row.location !== 'READY_TO_CROWDFUNDING'}
        />
      ),
    },
  ];

  const pool: GridColDef[] = [
    {
      field: 'proposal',
      headerName: 'Proposal',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <span className="MuiDataGrid-cellContent">{params.row.proposal.name}</span>;
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'periodStart',
      headerName: 'Start',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => moment(params.row.periodStart).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'periodFinish',
      headerName: 'Finish',
      editable: false,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => moment(params.row.periodFinish).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'duration',
      headerName: 'Duration',
      editable: false,
      sortable: false,
      hideSortIcons: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        const {days, hours, minutes, seconds} = secondsToTime(params.row.duration);
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
      },
    },
    {
      field: 'lifecycleStatus',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      sortable: true,
      hideSortIcons: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      renderCell: (params): any => (
        <RowPoolActions pool={params.row}/>
      ),
    },
  ];

  const location: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'percent',
      headerName: 'Percent',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'userName',
      headerName: 'Owner',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'venueName',
      headerName: 'Venue',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'lifecycleStatus',
      headerName: 'Lifecycle status',
      editable: false,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: true,
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        if (params.row.lifecycleStatus === 'draft') {
          return <span className={styles.negative}>draft</span>;
        }
      },
    },
  ];

  const guild: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'members',
      headerName: 'Members',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'ownerName',
      headerName: 'Owner',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
  ];

  const earlyAccess: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: true,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'appType',
      headerName: 'App Type',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'appWeb3',
      headerName: 'App Web3',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'message',
      headerName: 'Message',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: true,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
  ];

  const tokenPrice: GridColDef[] = [
    {
      field: 'price',
      headerName: 'Price',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'source',
      headerName: 'Source',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
  ];

  const bridgeOrder: GridColDef[] = [
    {
      field: 'orderId',
      headerName: 'Order Id',
      editable: false,
      flex: 1,
      minWidth: 100,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'tokenId',
      headerName: 'Token Id',
      editable: false,
      flex: 1,
      minWidth: 100,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'chainIdFrom',
      headerName: 'Chain Id From',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'chainIdTo',
      headerName: 'Chain Id To',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.row.status === 'FAILED_CLOSE') {
          return <span className={styles.negative}>FAILED_CLOSE</span>;
        }
      },
    },
    {
      field: 'statusTx',
      headerName: 'Status Tx',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'tries',
      headerName: 'Tries',
      editable: false,
      flex: 1,
      minWidth: 100,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'sender',
      headerName: 'Sender',
      editable: false,
      flex: 1,
      minWidth: 400,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'recipient',
      headerName: 'Recipient',
      editable: false,
      flex: 1,
      minWidth: 400,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
  ];

  const tokenTransactions: GridColDef[] = [
    {
      field: 'checkoutId',
      headerName: 'Checkout Id',
      editable: false,
      flex: 1,
      minWidth: 400,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'token',
      headerName: 'Token',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <span>{removeDecimals(params.row.token, 8)}</span>;
      },
    },
    {
      field: 'fiat',
      headerName: 'Fiat',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <span>${params.row.fiat}</span>;
      },
    },
    {
      field: 'price',
      headerName: 'Price',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <span>${params.row.price}</span>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (params.row.status === 'INVESTIGATE') {
          return <span className={ styles.negative }>{params.row.status}</span>;
        }
        return <span>{params.row.status}</span>;
      },
    },
    {
      field: `user.walletAddress`,
      headerName: 'Wallet address',
      editable: false,
      flex: 1,
      minWidth: 350,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return <span>{params.row.user?.walletAddress}</span>;
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: false,
      flex: 1,
      minWidth: 150,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Create date',
      editable: false,
      hideSortIcons: false,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      minWidth: 150,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params) => moment(params.row.createdAt).format('DD.MM.YYYY HH:mm'),
    },
  ];

  return {
    event,
    venue,
    user,
    creator,
    instance,
    avatar,
    eventRole,
    customAvatar,
    assistant,
    becomeCreator,
    proposal,
    pool,
    location,
    guild,
    tokenPrice,
    tokenTransactions,
    bridgeOrder,
    earlyAccess,
  };
};
