export enum ActionTypes {
  LOADING = '@avatar/LOADING',
  ERROR = '@avatar/ERROR',
  SET_TOKEN_TRANSACTIONS_LIST = '@avatar/SET_TOKEN_TRANSACTIONS_LIST',
}

export interface TokeTransactionsStateProps {
  itemsList: any;
  isLoading: boolean;
  isError: boolean;
}

export type Action =
  | {
      type: ActionTypes.LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.ERROR;
    }
  | {
      type: ActionTypes.SET_TOKEN_TRANSACTIONS_LIST;
      payload: any;
    };

const tokenTransactionsListState = {
  items: [],
  meta: {},
};

const initialState = {
  itemsList: tokenTransactionsListState,
  isLoading: false,
  isError: false,
};

export const tokenTransactionsReducer = (state: TokeTransactionsStateProps = initialState, action: Action): TokeTransactionsStateProps => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_TOKEN_TRANSACTIONS_LIST: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    default:
      return state;
  }
};
