import * as yup from 'yup';
import { Faction, Location } from '@/data/constants';

const webglSize = process.env.REACT_APP_FILE_WEBGL_SIZE;
const glbSize = process.env.REACT_APP_FILE_GLB_SIZE;
const pictureSize = process.env.REACT_APP_FILE_PICTURE_SIZE;
const webglSizeMB = glbSize / 1024 / 1024;
const glbSizeMB = glbSize / 1024 / 1024;
const pictureSizeMB = pictureSize / 1024 / 1024;

const fileSizeValidation = (maxFileSize) => (file) => {
  if (file) {
    return file[0].size <= maxFileSize;
  } else {
    return true;
  }
};

export const validationSettings = {
  name: {
    simple: yup.string().min(2, 'Name must be at least 2 characters'),
    required: yup.string().required('Enter name').min(2, 'Name must be at least 2 characters'),
  },
  username: {
    simple: yup.string().min(2, 'Name must be at least 2 characters'),
    required: yup.string().required('Enter name').min(2, 'Name must be at least 2 characters'),
  },
  email: {
    simple: yup.string().min(2, 'Email must be at least 2 characters'),
    required: yup.string().required('Enter email').min(2, 'Email must be at least 2 characters'),
  },
  sceneId: {
    simple: yup.string().nullable(),
    required: yup.string().required('Enter sceneId'),
  },
  status: {
    simple: yup.string(),
    required: yup.string().required('Enter venue status'),
  },
  tokenId: {
    simple: yup.string(),
    required: yup.string().required('Enter tokenId'),
  },
  videoUrl: {
    simple: yup.string().url().nullable(),
    required: yup.string().url().required('Enter video URL. https://example.com'),
  },
  secondVideoURL: {
    simple: yup.string().url().nullable(),
    required: yup.string().url().required('Enter second video URL. https://example.com'),
  },
  previewVideoUrl: {
    simple: yup.string().url().nullable(),
    required: yup.string().url().required('Enter preview video URL. https://example.com'),
  },
  selfAddressUrl: {
    simple: yup.string().url().nullable(),
    required: yup.string().url().required('Enter correct URL. https://example.com'),
  },
  mockupPrefubId: {
    simple: yup.string().nullable(),
    required: yup.string().required('Enter mockupPrefubId.'),
  },
  backstory: {
    simple: yup.string().nullable(),
    required: yup.string().required('Enter avatar\'s back story'),
  },
  description: {
    simple: yup.string().nullable(),
    required: yup.string().required('Enter description'),
  },
  webglFile: {
    simple: yup
      .mixed()
      .test('fileSize', `File size too large, max file size is ${webglSizeMB} Mb`, fileSizeValidation(webglSize)),
    required: yup
      .mixed()
      .required('Select WebGL archive')
      .test('fileSize', `File size too large, max file size is ${webglSizeMB} Mb`, fileSizeValidation(webglSize)),
  },
  glbFile: {
    simple: yup
      .mixed()
      .test('fileSize', `File size too large, max file size is ${glbSizeMB} Mb`, fileSizeValidation(glbSize)),
    required: yup
      .mixed()
      .required('Select GLB model')
      .test('fileSize', `File size too large, max file size is ${glbSizeMB} Mb`, fileSizeValidation(glbSize)),
  },
  thumbnailFile: {
    simple: yup
      .mixed()
      .test('fileSize', `File size too large, max file size is ${pictureSizeMB} Mb`, fileSizeValidation(pictureSize)),
    required: yup
      .mixed()
      .required('Select thumbnail image')
      .test('fileSize', `File size too large, max file size is ${pictureSizeMB} Mb`, fileSizeValidation(pictureSize)),
  },
  coverFile: {
    simple: yup
      .mixed()
      .test('fileSize', `File size too large, max file size is ${pictureSizeMB} Mb`, fileSizeValidation(pictureSize)),
    required: yup
      .mixed()
      .required('Select cover image')
      .test('fileSize', `File size too large, max file size is ${pictureSizeMB} Mb`, fileSizeValidation(pictureSize)),
  },
  title: {
    simple: yup.string().min(2, 'Title must be at least 2 characters'),
    required: yup.string().required('Enter title').min(2, 'Title must be at least 2 characters'),
  },
  venueId: {
    simple: yup.string(),
    required: yup.string().required('Select Venue'),
  },
  poolId: {
    simple: yup.string(),
    required: yup.string().required('Select Pool'),
  },
  stream: {
    simple: yup.string().min(2, 'Stream must be at least 2 characters'),
    required: yup.string().required('Enter stream').min(2, 'Stream must be at least 2 characters'),
  },
  startAt: {
    simple: yup.date().nullable(),
    required: yup.date().required('Select start time'),
  },
  finishAt: {
    simple: yup.date().nullable(),
    required: yup.date().nullable().required('Select finish time'),
  },
  logUrl: {
    simple: yup.string().url().nullable(),
    required: yup.string().url().required('Enter correct URL. https://example.com'),
  },
  voipStream: {
    simple: yup.string().url().nullable(),
    required: yup.string().url().required('Enter correct URL. https://example.com'),
  },
  adminStatus: {
    simple: yup.string().matches(/(PENDING|APPROVED|REJECTED|BLOCKED)/),
    required: yup
      .string()
      .matches(/(PENDING|APPROVED|REJECTED|BLOCKED)/)
      .required('Select admin status'),
  },
  faction: {
    simple: yup.string().nullable().oneOf(Object.values(Faction)),
    required: yup
      .string()
      .nullable()
      .oneOf(Object.values(Faction))
      .required('Select faction'),
  },
  location: {
    simple: yup.string().nullable().oneOf(Object.values(Location)),
    required: yup
      .string()
      .nullable()
      .oneOf(Object.values(Location))
      .required('Select location'),
  },
  clientStatus: {
    simple: yup.string().matches(/(INACTIVE|ACTIVE|ONAIR|FINISHED)/),
    required: yup
      .string()
      .matches(/( INACTIVE|ACTIVE|ONAIR|FINISHED)/)
      .required('Select client status'),
  },
  slots: {
    simple: yup.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0),
    required: yup
      .number()
      .required('ERROR: The number is required!')
      .test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0),
  },
  interactions: {
    simple: yup.number().test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0),
    required: yup
      .number()
      .required('ERROR: The number is required!')
      .test('Is positive?', 'ERROR: The number must be greater than 0!', (value) => value > 0),
  },
  password: {
    simple: yup.string().min(8, 'Title must be at least 8 characters'),
    required: yup.string().min(8, 'Title must be at least 8 characters').required('Password is required'),
  },
  passwordConfirmation: {
    simple: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    required: yup
      .string()
      .required('Enter correct password')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  },
  type: {
    simple: yup.string(),
    required: yup.string().required('Choose type'),
  },
  gender: {
    simple: yup.string(),
    required: yup.string().required('Choose gender'),
  },
  isActive: {
    simple: yup.string(),
    required: yup.string().required('Choose active type'),
  },
  permissions: {
    simple: yup.array().min(1),
    required: yup.array().min(1).required('Choose permissions type'),
  },
  role: {
    simple: yup.string().nullable(),
    required: yup.string().required('Enter data to setup role for a user.'),
  },
  decision: {
    simple: yup.string().matches(/(APPROVE|REJECT)/),
    required: yup
      .string()
      .matches(/(APPROVE|REJECT)/)
      .required('Select decision'),
  },
  proposalId: {
    simple: yup.string(),
    required: yup.string().required('Select Proposal'),
  },
  periodStart: {
    simple: yup.date().nullable(),
    required: yup.date().required('Select start time'),
  },
  periodFinish: {
    simple: yup.date().nullable(),
    required: yup.date().nullable().required('Select finish time'),
  },
  amount: {
    simple: yup.number().test('Is positive?', 'Amount must be greater than 0!', (value) => value > 0),
    required: yup
      .number()
      .required('Amount is required!')
      .test('Is positive?', 'Amount must be greater than 0!', (value) => value > 0),
  },
  duration: {
    simple: yup.number().test('Is positive?', 'Duration must be greater than 0!', (value) => value > 0),
    required: yup
      .number()
      .required('Duration is required!')
      .test('Is positive?', 'Duration must be greater than 0!', (value) => value > 0),
  },
  userId: {
    simple: yup.string().nullable(),
    required: yup.string().required('Choose user'),
  },
};
