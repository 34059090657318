import { Layout } from '@/core/layout';
import { Frame } from '@/shared/components/Frame';
import { Table } from '@/shared/components/Table';

export const TokenPricePage = () => {
  return (
    <Layout backgroundType="none">
      <Frame>
        <h1>Token Price history</h1>
        <Table />
      </Frame>
    </Layout>
  );
};
