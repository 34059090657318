export const AUTH_API = `${process.env.REACT_APP_PUBLIC_AUTH_API}/api`;
export const VENUE_API = `${process.env.REACT_APP_PUBLIC_VENUE_API}/api`;
export const EVENT_API = `${process.env.REACT_APP_PUBLIC_EVENT_API}/api`;
export const AVATAR_API = `${process.env.REACT_APP_PUBLIC_AVATAR_API}/api`;
export const GUILD_API = `${process.env.REACT_APP_PUBLIC_GUILD_API}/api`;

export const authPaths = {
  login: `${AUTH_API}/v1/auth/sign-in`,
  logout: `${AUTH_API}/v1/auth/logout`,
  refreshToken: `${AUTH_API}/v1/auth/refresh`,
  getProfile: `${AUTH_API}/v1/user/profile`,
  user: `${AUTH_API}/v1/user`,
  tokenPrice: `${AUTH_API}/v1/admin/token`,
  tokenTransactions: `${AUTH_API}/v1/admin/token/transactions`,
  bridgeOrder: `${AUTH_API}/v1/admin/bridge/orders`,
  earlyAccess: `${AUTH_API}/v1/admin/auth/early-access`,
};

export const userPaths = {
  getUserData: `${AUTH_API}/v1/user`,
  userDetailsAdmin: `${AUTH_API}/v1/admin/user`,
  getUsersByIds: `${AUTH_API}/v1/user/by-ids`,
  createUser: `${AUTH_API}/v1/auth/admin/create-user`,
  getRolesList: `${AUTH_API}/v1/role`,
  addUserRole: `${AUTH_API}/v1/role/assign`,
  removeUserRole: `${AUTH_API}/v1/role/discharge`,
  bunUser: `${AUTH_API}/v1/user/ban`,
  unbunUser: `${AUTH_API}/v1/user/unban`,
  userStat: `${AUTH_API}/v1/statistic/user`,
};

export const creatorsPath = {
  role: (id: string) => `${AUTH_API}/v1/role/${id}`,
  getRoleRequests: `${AUTH_API}/v1/role/request`,
  changeRequestStatus: (requestId: string) => `${AUTH_API}/v1/role/request/${requestId}/status`,
};

export const venuePaths = {
  venue: `${VENUE_API}/v1/admin/venue`,
  venueById: (id: any) => `${VENUE_API}/v1/admin/venue/${id}`,
  mintVenue: (venueId: string) => `${VENUE_API}/v1/venue/${venueId}/mint`,
  mintLocations: (venueId: string) => `${VENUE_API}/v1/location/venue/${venueId}/mint`,
  venueStat: `${VENUE_API}/v1/admin/stat/venue`,
};

export const eventPaths = {
  event: `${EVENT_API}/v1/event`,
  eventById: (id: any) => `${EVENT_API}/v1/event/${id}`,
  eventSetRoleToUser: `${EVENT_API}/v1/event/role/set`,
  eventStat: `${EVENT_API}/v1/statistic/event`,
};

export const eventRolePaths = {
  eventRole: `${EVENT_API}/v1/event/role`,
  eventRoleById: (id: any) => `${EVENT_API}/v1/event/role/${id}`,
  eventRoleDelete: (id: any) => `${EVENT_API}/v1/event/role/${id}`,
  permission: `${EVENT_API}/v1/event/role/permissions`,
};

export const instancePaths = {
  instance: `${EVENT_API}/v1/instance`,
  instanceById: (id: any) => `${EVENT_API}/v1/instance/${id}`,
  instanceStat: `${EVENT_API}/v1/statistic/instance`,
};

export const avatarPaths = {
  avatar: `${AVATAR_API}/v1/admin/avatar`,
  avatarById: (id: any) => `${AVATAR_API}/v1/avatar/${id}`,
  getDefaultAvatar: (id: string) => `${AVATAR_API}/v1/avatar/user/${id}/default`,
  avatarStat: `${AVATAR_API}/v1/stat/avatar`,
};

export const assistantPaths = {
  assistant: `${AVATAR_API}/v1/admin/assistant`,
  assistantById: (id: any) => `${AVATAR_API}/v1/admin/assistant/${id}`,
  knowledgeBase: `${AVATAR_API}/v1/admin/assistant/knowledgeBase`,
};

export const customAvatarPaths = {
  customAvatar: `${AVATAR_API}/v1/custom-avatar`,
  assignAvatar: `${AVATAR_API}/v1/custom-avatar/assign`,
  customAvatarById: (id: any) => `${AVATAR_API}/v1/custom-avatar/${id}`,
  customAvatarStat: `${AVATAR_API}/v1/stat/custom-avatar`,
};

export const proposalPaths = {
  proposal: `${VENUE_API}/v1/proposal`,
  proposalById: (id: any) => `${VENUE_API}/v1/proposal/${id}`,
  proposalDecision: `${VENUE_API}/v1/proposal/review`,
};

export const poolPaths = {
  poolAdmin: `${VENUE_API}/v1/admin/pool`,
  pool: `${VENUE_API}/v1/pool`,
  poolById: (id: any) => `${VENUE_API}/v1/pool/${id}`,
};

export const locationPaths = {
  location: `${VENUE_API}/v1/admin/location`
};

export const guildPaths = {
  guild: `${GUILD_API}/v1/guild`
};
