import { Layout } from '@/core/layout';
import { Frame } from '@/shared/components/Frame';
import { Create } from '@/core/components/Create';
import { useHasRole } from "@/core/hooks/useHasRole";

export const TokenPriceCreatePage = (): JSX.Element => {
  return (
    <Layout backgroundType="none">
      <Frame>
        {useHasRole('SUPER_ADMIN') && (
          <Create />
        )}
      </Frame>
    </Layout>
  );
};
