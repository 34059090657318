import { authApi } from '@/api';
import {TypedDispatch} from '@/core/models';
import {notify} from '@/shared/components/Notify/Notify';
import {FilterProps} from '@/core/hooks/useGetFilteredDataFromService';
import {ActionTypes} from '../reducers';

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetEarlyAccessList = (data: any) => ({
  type: ActionTypes.SET_EARLY_ACCESS,
  payload: data,
});

export const actionGetEarlyAccessList = (filters?: FilterProps) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const response = await authApi.getEarlyAccessList(filters);

    if (response.data.success) {
      dispatch(actionSetEarlyAccessList(response.data.data));
    } else {
      throw response.data.message;
    }
  } catch (e: any) {
    dispatch({type: ActionTypes.ERROR});
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};