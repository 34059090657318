import { Status } from "@/data/constants";

export const createFieldNames = {
  venue: {
    name: '',
    poolId: undefined,
    sceneId: '',
    thumbnailFile: undefined,
    coverFile: undefined,
    description: '',
    faction: '',
    location: '',
    status: Status.DRAFT,
  },
  event: {
    title: '',
    venueName: '',
    startAt: '',
    finishAt: '',
    logUrl: '',
    adminStatus: '',
    clientStatus: '',
    stream: '',
    thumbnailFile: undefined,
    coverFile: undefined,
    venueId: '', // Is hidden from user, not added in ./formCreateFields.js
    description: '',
  },
  eventRole: {
    name: '',
    permissions: [],
  },
  user: {
    username: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    isActivated: '',
    roleId: '',
    faction: undefined,
  },
  instance: {
    venueId: '',
    slots: 0,
    selfAddressUrl: '',
    voipStream: '',
    logUrl: '',
  },
  customAvatar: {
    name: '',
    type: '',
    gender: '',
    isActive: '',
    glbFile: undefined,
    thumbnailFile: undefined,
    description: '',
  },
  pool: {
    proposalId: '',
    periodStart: '',
    periodFinish: '',
    amount: '',
    duration: '',
  },
  tokenPrice: {
    price: '',
  },
};
