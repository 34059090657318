export const REALITYJAM_URL = process.env.REACT_APP_PUBLIC_MARKETPLACE_DASHBOARD;
const realityjam = `${REALITYJAM_URL}`;
const deusxDashboard = `${REALITYJAM_URL}/deusx`;
const vrjamDashboard = `${REALITYJAM_URL}/vrjam`;
const aijamDashboard = `${REALITYJAM_URL}/aijam`;
const dimensionDashboard = `${REALITYJAM_URL}/5thdimension`;
const adminDashboard = process.env.REACT_APP_PUBLIC_ADMIN_DASHBOARD;

export const paths: any = {
  links: {
    home: 'https://vrjam.com/',
    press: 'https://vrjam.com/press/',
    publicRealm: 'https://vrjam.com/public-realm/',
    roadmap: 'https://vrjam.com/roadmap/',
    whitepaper: 'https://bit.ly/VRJAMwhitepaper',
    dimension: 'https://www.5thdimension.gg',
    decentraland: 'https://vrjam.web.app/',
  },

  realityjam: {
    root: `${realityjam}/`,
    home: `${realityjam}/home`,
    createAvatar: `${realityjam}/create-avatar`,
    login: `${realityjam}/login`,
    signUp: `${realityjam}/signup`,
    resetPass: `${realityjam}/reset-password`,
    verifyUser: `${realityjam}/verification`,
    forgotPassword: `${realityjam}/forgot-password`,
    faq: `${realityjam}/faq`,
    account: `${realityjam}/account`,
    settings: `${realityjam}/settings`,
    policy: `${realityjam}/policy`,
    transactions: `${realityjam}/transactions`,
    getInTouch: `${realityjam}/#getInTouch`,
    search: `${realityjam}/search`,
  },

  deusx: {
    home: `${deusxDashboard}`,
    deusx: `${deusxDashboard}/deusx`,
    // venues: `${deusxDashboard}/venues`,
    // venueDetails: `${deusxDashboard}/venues/[id]`,
    // nftListings: `${deusxDashboard}/nft-listings`,
    // nftDetails: `${deusxDashboard}/nft-listings/[id]`,
    // creator: `${deusxDashboard}/creator`,
    staking: `${deusxDashboard}/staking`,
    vesting: `${deusxDashboard}/vesting`,
    // worldMap: `${deusxDashboard}/world-map`,
    onRamp: `${deusxDashboard}/on-ramp`,
    bridge: `${deusxDashboard}/bridge`,
  },

  vrjam: {
    home: `${vrjamDashboard}`,
    avatarGallery: `${vrjamDashboard}/avatar-gallery`,
    avatarDetails: `${vrjamDashboard}/avatar-gallery/[id]`,
    myNFTs: `${vrjamDashboard}/my-inventory`,
    myNFTsDetails: `${vrjamDashboard}/my-inventory/[id]`,
    guilds: `${vrjamDashboard}/guilds`,
    createGuild: `${vrjamDashboard}/create-guild`,
    guildDetails: `${vrjamDashboard}/guilds/[id]`,
    editGuild: `${vrjamDashboard}/edit-guild/[id]`,
    events: `${vrjamDashboard}/events`,
    eventDetails: `${vrjamDashboard}/events/[id]`,
    createEvent: `${vrjamDashboard}/create-event`,
    becomeCreator: `${vrjamDashboard}/become-a-creator`,
    editAvatar: `${vrjamDashboard}/edit-avatar/[id]`,
    editEvent: `${vrjamDashboard}/edit-event/[id]`,
    venues: `${vrjamDashboard}/venues`,
    venueDetails: `${vrjamDashboard}/venues/[id]`,
    createVenue: `${vrjamDashboard}/venues/create`,
    proposalsDetails: `${vrjamDashboard}/proposals/[id]`,
    createProposal: `${vrjamDashboard}/create-proposal`,
    poolsDetails: `${vrjamDashboard}/pools/[id]`,
    venueManagement: `${vrjamDashboard}/venues/[id]/management`,
    marketplace: `${vrjamDashboard}/marketplace`,
    nftListings: `${vrjamDashboard}/marketplace/nft-listings`,
    nftDetails: `${vrjamDashboard}/marketplace/nft-listings/[id]`,
    creator: `${vrjamDashboard}/creator`,
  },

  aijam: {
    home: `${aijamDashboard}`,
    myNFTs: `${aijamDashboard}/my-inventory`,
    myNFTsDetails: `${aijamDashboard}/my-inventory/[id]`,
    assistantGallery: `${aijamDashboard}/assistant-gallery`,
    assistantDetails: `${aijamDashboard}/assistant-gallery/[id]`,
    requestAssistant: `${aijamDashboard}/request-assistant`,
    marketplace: `${aijamDashboard}/marketplace`,
    nftDetails: `${aijamDashboard}/marketplace/nft-listings/[id]`,
  },

  dimension: {
    home: `${dimensionDashboard}`,
    marketplace: `${dimensionDashboard}/marketplace`,
    avatarGallery: `${dimensionDashboard}/avatar-gallery`,
    avatarDetails: `${dimensionDashboard}/avatar-gallery/[id]`,
    editAvatar: `${dimensionDashboard}/edit-avatar/[id]`,
    assetGallery: `${dimensionDashboard}/asset-gallery`,
    assetDetails: `${dimensionDashboard}/asset-gallery/[id]`,
    editAsset: `${dimensionDashboard}/edit-asset/[id]`,
    myNFTs: `${dimensionDashboard}/my-inventory`,
    myNFTsDetails: `${dimensionDashboard}/my-inventory/[id]`,
    createAsset: `${dimensionDashboard}/create-asset`,
  },

  admin: {
    home: '/',
    users: '/users',
    usersEdit: '/users/:editId',
    usersCreate: '/users/create',
    venues: '/venues',
    editVenue: '/venues/:editId',
    createVenue: '/venues/create',
    setOwnerVenue: '/venues/:venueId/venueOwner',
    events: '/events',
    eventCreate: '/events/create',
    eventEdit: '/events/:editId',
    eventEditRole: '/events/:editId/eventRoleToUser',
    eventRoles: '/eventRoles',
    eventRolesEdit: '/eventRoles/:editId',
    eventRolesCreate: '/eventRoles/create',
    creators: '/creators',
    creatorEdit: '/creators/:editId',
    becomeCreator: '/becomeCreator',
    instances: '/instances',
    instanceCreate: '/instances/create',
    instanceEdit: '/instances/:editId',
    avatars: '/avatars',
    avatarEdit: '/avatars/:editId',
    customAvatars: '/customAvatars',
    customAvatarCreate: '/customAvatars/create',
    customAvatarEdit: '/customAvatars/:editId',
    assistants: '/assistants',
    assistantEdit: '/assistants/:editId',
    assistantInteractionsEdit: '/assistants/:editId/interactions',
    proposals: '/proposals',
    proposalsEdit: '/proposals/:editId',
    pools: '/pools',
    poolCreate: '/pools/create',
    poolEdit: '/pools/:editId',
    locations: '/locations',
    guilds: '/guilds',
    assignCustomAvatar: '/customAvatars/:editId/assignAvatar',
    tokenPrice: '/tokenPrice',
    tokenPriceCreate: '/tokenPrice/create',
    tokenTransactions: '/tokenTransactions',
    bridgeOrder: '/bridgeOrder',
    earlyAccess: '/earlyAccess',
  },
};
