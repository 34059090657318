import { ethers, BigNumberish } from 'ethers';
import { maxFractions } from "./maxFractions";

export function applyDecimals(value: BigNumberish, decimals: BigNumberish): string {
  return ethers.parseUnits(maxFractions(value.toString(), decimals), decimals).toString();
}

export function removeDecimals(value: BigNumberish, decimals: BigNumberish): string {
  return maxFractions(ethers.formatUnits(value.toString(), decimals), decimals);
}
