export enum ActionTypes {
  LOADING = '@earlyAccess/LOADING',
  ERROR = '@earlyAccess/ERROR',
  SET_EARLY_ACCESS = '@earlyAccess/SET_EARLY_ACCESS',
}

export type Action = {
  type: ActionTypes.LOADING;
  payload: boolean;
} | {
  type: ActionTypes.ERROR,
  payload: boolean;
} | {
  type: ActionTypes.SET_EARLY_ACCESS;
  payload: any;
};

export interface LocationState {
  itemsList: {
    items: any[];
    meta: Record<string, any>
  };
  isLoading: boolean;
  isError: boolean;
}

const initialState: LocationState = {
  itemsList: {
    items: [],
    meta: {}
  },
  isLoading: false,
  isError: false,
};

export const earlyAccessReducer = (state = initialState, action: Action): LocationState => {
  switch (action.type) {
    case ActionTypes.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case ActionTypes.ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    case ActionTypes.SET_EARLY_ACCESS: {
      return {
        ...state,
        itemsList: action.payload,
        isError: false,
      };
    }

    default:
      return state;
  }
};
