import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from '@mui/material';

import { paths } from '@/data/paths';
import { actionNativeLogout } from '@/modules/Auth/actions';
import { useTypedSelector } from '@/core/hooks/useTypedSelector';
import { normalizeFileUrl } from '@/core/utils/fileStorageHandler';
import { menuItems } from './menuItems';

import { ReactComponent as AccountLogout } from '@/resources/icons/account-logout.svg';

import styles from './Account.module.scss';

interface AccountProps {
  showName?: boolean;
}

export const Account = ({ showName = false }: AccountProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | undefined>(null);
  const isOpen = Boolean(anchorEl);
  const dispatch = useDispatch();

  const { user, defaultAvatar } = useTypedSelector(({ authReducer }) => authReducer);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClickItem = (path: string) => () => {
    setAnchorEl(null);
    window.location.replace(path);
  };

  const handleLogoutClick = async () => {
    await dispatch(actionNativeLogout() as any);
    window.location.replace(paths.realityjam.home);
  };

  return (
    <>
      <div
        className={styles.account}
        onClick={handleOpen}
      >
        <div className={styles.account__thumbnail}>
          {defaultAvatar && (
            <img
              alt="user default avatar"
              src={normalizeFileUrl(defaultAvatar.thumbnailLocation)}
            />
          )}
        </div>
        {showName && <div className={styles.account__username}>{user?.username}</div>}
      </div>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        classes={{ paper: styles.account__dropdown }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={onClickItem(item.path)}
            className={styles.account__dropdown_item}
          >
            <item.icon className={styles.account__dropdown_item_icon}/>
            {item.title}
          </MenuItem>
        ))}
        {!showName && (
          <MenuItem
            className={styles.account__dropdown_item}
            onClick={handleLogoutClick}
          >
            <AccountLogout
              className={styles.account__dropdown_item_icon}
            /> Logout
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
