import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { ActionTypes } from '../reducers';
import { authApi } from '@/api';
import { FilterProps } from "@/core/hooks/useGetFilteredDataFromService";

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetTokenPriceList = (data: any) => ({
  type: ActionTypes.SET_TOKEN_PRICE_LIST,
  payload: data,
});

export const actionGetTokenPriceList = (filters?: FilterProps) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const response = await authApi.getTokenPrice(filters);

    if (response.data.success) {
      dispatch(actionSetTokenPriceList(response.data.data));
    } else {
      dispatch({ type: ActionTypes.ERROR });
      throw response.data.message;
    }
  } catch (e: any) {
    dispatch({ type: ActionTypes.ERROR });
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};

export const actionCreateTokenPriceItem = (body: any) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));

    const response = await authApi.createTokenPriceItem(body);

    if (response.data.success) {
      notify.success('New token price added successfully');
    } else {
      throw response.data.message;
    }
  } catch (e: any) {
    const error = e.response?.data?.message || e.message || e;

    notify.error(error);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};