import { paths } from './paths';

export const mainNavigation = {
  links: [
    {
      label: 'VRJAM',
      path: paths.vrjam.home,
    },
    {
      label: 'AiJAM',
      path: paths.aijam.home,
    },
    {
      label: '5th Dimension',
      path: paths.dimension.home,
    },
    {
      label: 'DeusX',
      path: paths.deusx.home,
    },
  ],

  dropdowns: [
    {
      label: 'Admin',
      options: [
        {
          label: 'Home',
          path: paths.admin.home,
        },
        {
          label: 'Venues',
          path: paths.admin.venues,
        },
        {
          label: 'Events',
          path: paths.admin.events,
        },
        {
          label: 'Event roles',
          path: paths.admin.eventRoles,
        },
        {
          label: 'Users',
          path: paths.admin.users,
        },
        {
          label: 'Creators',
          path: paths.admin.creators,
        },
        {
          label: 'Instances',
          path: paths.admin.instances,
        },
        {
          label: 'Avatars',
          path: paths.admin.avatars,
        },
        {
          label: 'Custom avatars',
          path: paths.admin.customAvatars,
        },
        {
          label: 'Assistants',
          path: paths.admin.assistants,
        },
        {
          label: 'Proposals',
          path: paths.admin.proposals,
        },
        {
          label: 'Pools',
          path: paths.admin.pools,
        },
        {
          label: 'Locations',
          path: paths.admin.locations,
        },
        {
          label: 'Guilds',
          path: paths.admin.guilds,
        },
      ],
    },
  ],
};
