import { notify } from '@/shared/components/Notify/Notify';
import { TypedDispatch } from '@/core/models';
import { ActionTypes } from '../reducers';
import { authApi } from '@/api';
import { FilterProps } from "@/core/hooks/useGetFilteredDataFromService";

export const setLoading = (loading: boolean) => ({
  type: ActionTypes.LOADING,
  payload: loading,
});

export const actionSetBridgeOrderList = (data: any) => ({
  type: ActionTypes.SET_BRIDGE_ORDER_LIST,
  payload: data,
});

export const actionGetBridgeOrderList = (filters?: FilterProps) => async (dispatch: TypedDispatch): Promise<void> => {
  try {
    dispatch(setLoading(true));
    const response = await authApi.getBridgeOrder(filters);

    if (response.data.success) {
      dispatch(actionSetBridgeOrderList(response.data.data));
    } else {
      dispatch({ type: ActionTypes.ERROR });
      throw response.data.message;
    }
  } catch (e: any) {
    dispatch({ type: ActionTypes.ERROR });
    const error = e.response?.data?.message || e.message || e;
    notify.error(error);
    throw e;
  } finally {
    dispatch(setLoading(false));
  }
};
