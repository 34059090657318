import { useState } from 'react';
import { Box, Link } from '@mui/material';

import { MobileMenu } from '@/core/components/MobileMenu';
import { Button } from '@/shared/components/Button';
import { Account } from '@/core/components/Account';
import { Wrapper } from '@/core/components/Wrapper';
import { useTypedSelector } from '@/core/hooks/useTypedSelector';
import { Dropdown } from '@/core/components/Dropdown';
import { mainNavigation } from '@/data/mainNavigation';
import { paths } from '@/data/paths';

import LogoImage from '@/resources/images/realityjam.png';
import { ReactComponent as MobileMenuIcon } from '@/resources/icons/mobile-menu-icon.svg';

import styles from './Header.module.scss';

export const Header = () => {
  const { user, isLoading } = useTypedSelector(({ authReducer }: any) => authReducer);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleSignInClick = () =>
    window.location.replace(paths.realityjam.login); /* Must be redirect to deployeed login*/

  const handleMMClick = () => setMobileMenuOpen(true);

  return (
    <>
      <header className={styles.header}>
        <Wrapper>
          <div className={styles.header__inner}>
            <div className={styles.header__inner_left}>
              <Link
                href="/"
                className={styles.header__inner_logo}
              >
                <img
                  src={LogoImage}
                  alt="Logo"
                />
              </Link>
              <nav className={styles.header__inner_nav}>
                {mainNavigation.dropdowns.map((item, index) => (
                  <Dropdown
                    key={index}
                    title={item.label}
                    options={item.options}
                  />
                ))}
                {mainNavigation.links.map((link, index) => (
                  <a
                    key={index}
                    target='_blank'
                    rel='noreferrer'
                    href={link.path}
                    className={styles.header__link}
                  >
                    {link.label}
                  </a>
                ))}
              </nav>
            </div>
            {!isLoading &&
              (!user ? (
                <Button
                  className={styles.header__inner_signIn}
                  onClick={handleSignInClick}
                >
                  Sign in
                </Button>
              ) : (
                <div className={styles.header__inner_account}>
                  <Account />
                </div>
              ))}
            {/* <Box className={styles.header__inner_mobile_search}></Box> */}
            <Box
              className={styles.header__inner_mobile_icon}
              onClick={handleMMClick}
            >
              <MobileMenuIcon />
            </Box>
          </div>
        </Wrapper>
      </header>
      <div className={styles.header__height} />

      <MobileMenu
        open={mobileMenuOpen}
        setOpen={setMobileMenuOpen}
      />
    </>
  );
};
