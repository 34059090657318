import { paths } from './paths';

import { ReactComponent as DashboardIcon } from '../resources/icons/category.svg';
import { ReactComponent as AvatarGalleryIcon } from '../resources/icons/add-user.svg';
import { ReactComponent as EventIcon } from '@/resources/icons/events.svg';
import { ReactComponent as DiamantIcon } from '@/resources/icons/account-diamond.svg';
import { ReactComponent as WalletIcon } from '@/resources/icons/wallet.svg';
import { ReactComponent as AvatarIcon } from '@/resources/icons/users.svg';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
import MediationIcon from '@mui/icons-material/Mediation';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

export const sidebarNavigation = {
  top: [
    {
      icon: DashboardIcon,
      name: 'Dashboard',
      href: paths.admin.home,
    },
    {
      icon: WalletIcon,
      name: 'Venues',
      href: paths.admin.venues,
      hrefPlus: paths.admin.createVenue,
      hasPlus: true,
    },
    {
      icon: EventIcon,
      name: 'Events',
      href: paths.admin.events,
      hrefPlus: paths.admin.eventCreate,
      hasPlus: true,
    },
    {
      icon: EventIcon,
      name: 'Event roles',
      href: paths.admin.eventRoles,
      hrefPlus: paths.admin.eventRolesCreate,
      hasPlus: true,
    },
    {
      icon: AvatarGalleryIcon,
      name: 'Users',
      href: paths.admin.users,
      hrefPlus: paths.admin.usersCreate,
      hasPlus: true,
    },
    {
      icon: DiamantIcon,
      name: 'Creators',
      href: paths.admin.creators,
    },
    {
      icon: MediationIcon,
      name: 'Instances',
      href: paths.admin.instances,
      hrefPlus: paths.admin.instanceCreate,
      hasPlus: true,
    },
    {
      icon: AvatarIcon,
      name: 'Avatars',
      href: paths.admin.avatars,
      hasPlus: false,
    },
    {
      icon: AvatarIcon,
      name: 'Custom avatars',
      href: paths.admin.customAvatars,
      hrefPlus: paths.admin.customAvatarCreate,
      hasPlus: true,
    },
    {
      icon: AvatarIcon,
      name: 'Assistants',
      href: paths.admin.assistants,
      hasPlus: false,
    },
    {
      icon: ArticleIcon,
      name: 'Proposals',
      href: paths.admin.proposals,
      hrefPlus: paths.admin.proposals,
      hasPlus: false,
    },
    {
      icon: AccountBalanceIcon,
      name: 'Pools',
      href: paths.admin.pools,
      hrefPlus: paths.admin.poolCreate,
      hasPlus: true,
    },
    {
      icon: AccountBalanceIcon,
      name: 'Locations',
      href: paths.admin.locations,
    },
    {
      icon: AvatarIcon,
      name: 'Guilds',
      href: paths.admin.guilds,
    },
    {
      icon: PriceChangeIcon,
      name: 'Token Price',
      href: paths.admin.tokenPrice,
    },
    {
      icon: CompareArrowsIcon,
      name: 'Token Transactions',
      href: paths.admin.tokenTransactions,
    },
    {
      icon: CompareArrowsIcon,
      name: 'Bridge Orders',
      href: paths.admin.bridgeOrder,
    },
    {
      icon: AppRegistrationIcon,
      name: 'Early Access',
      href: paths.admin.earlyAccess,
    },
  ],
  bottom: [],
};
