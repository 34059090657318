import { combineReducers } from 'redux';
import { authReducer } from '@/modules/Auth/reducers';
import { venueReducer } from '@/modules/VenuesPage/reducers';
import { eventReducer } from '@/modules/EventsPage/reducers';
import { userReducer } from '@/modules/UsersPage/reducers';
import { creatorsReducer } from '@/modules/CreatorsPage/reducers';
import { becomeCreatorReducer } from '@/modules/BecomeCreatorPage/reducers';
import { instanceReducer } from '@/modules/InstancePage/reducers';
import { avatarReducer } from '@/modules/AvatarsPage/reducers';
import { eventRolesReducer } from '@/modules/EventRolesPage/reducers';
import { customAvatarReducer } from '@/modules/CustomAvatarsPage/reducers';
import { assistantReducer } from '@/modules/AssistantsPage/reducers';
import { proposalReducer } from '@/modules/ProposalPage/reducers';
import { poolReducer } from '@/modules/PoolPage/reducers';
import { locationReducer } from '@/modules/LocationsPage/reducers';
import { guildReducer } from '@/modules/GuildsPage/reducers';
import { connectReducer } from '@/modules/Connect/reducers';
import { tokenPriceReducer } from '@/modules/TokenPricePage/reducers';
import { tokenTransactionsReducer } from '@/modules/TokenTransactionsPage/reducers';
import { bridgeOrderReducer } from '@/modules/BridgeOrdersPage/reducers';
import { earlyAccessReducer } from '@/modules/EarlyAccessPage/reducers';

export const rootReducer = combineReducers({
  authReducer,
  venueReducer,
  eventReducer,
  userReducer,
  creatorsReducer,
  becomeCreatorReducer,
  instanceReducer,
  avatarReducer,
  eventRolesReducer,
  customAvatarReducer,
  proposalReducer,
  poolReducer,
  locationReducer,
  guildReducer,
  connectReducer,
  assistantReducer,
  tokenPriceReducer,
  tokenTransactionsReducer,
  bridgeOrderReducer,
  earlyAccessReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
