import {useEffect, useState} from 'react';
import { useTypedSelector } from "@/core/hooks/useTypedSelector";


export const useHasRole = (roles: string | string[]): boolean => {
  const [hasRole, setHasRole] = useState<boolean>(false);
  const { user } = useTypedSelector(({ authReducer }) => authReducer);

  useEffect(() => {
    if (!user) return setHasRole(false);

    let isExist = false;
    if (typeof roles === 'string') {
      user.roles.map((r: any) => {
        if (r.name === roles) {
          isExist = true;
        }
      });
    } else {
      user.roles.map((r: any) => {
        if (roles.includes(r.name)) {
          isExist = true;
        }
      });
    }
    setHasRole(isExist);
  }, [user?.id]);

  return hasRole;
};
