import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MenuItem, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { paths } from '@/data/paths';

import useGetFilteredDataFromService, { FilterProps } from '@/core/hooks/useGetFilteredDataFromService';
import { correctServiceName } from '@/core/utils/correctServiceName';
import { serviceNameChecker } from '@/core/utils/serviceNameChecker';
import { capitalizeAllText } from '@/core/utils/capitalizeAllText';
import { SectionSearch } from '@/core/components/SectionSearch';
import { TableHead } from '@/shared/components/Table/components/TableHead';
import { TableBody } from '@/shared/components/Table/components/TableBody';
import { Pagination } from '@/shared/components/Pagination';
import { actionGetRolesList } from '@/modules/CreatorsPage/actions';
import { knowledgeBaseRequestStatusOptions } from '@/data/constants';

import styles from './Table.module.scss';
import { useHasRole } from "@/core/hooks/useHasRole";

const tabs = {
  creators: 'creator',
  becomeCreator: 'becomeCreator',
};

const searchBy = [
  {
    value: 'username',
    label: 'name',
  },
  {
    value: 'email',
    label: 'email',
  },
];

const sourceTokenPriceList = [
  {
    value: 'MANUAL',
    label: 'Manual',
  },
  {
    value: 'DEX',
    label: 'Dex',
  },
];


const initialFilter: FilterProps = {
  page: 1,
  limit: 10,
};

export const Table = () => {
  const dispatch = useDispatch();
  const currentPath = useLocation();
  const hasRole = useHasRole('SUPER_ADMIN');

  const [tab, setTab] = useState<boolean>(false);
  const [creatorId, setCreatorId] = useState<string | null>(null);
  const [searchingBy, setSearchigBy] = useState<string>('username');
  const [sourceTokenPrice, setSourceTokenPriceList] = useState<string>('MANUAL');

  const moduleName = currentPath.pathname.split('/')[1];
  const service = serviceNameChecker(moduleName);

  const { serviceData, filters, setFilters } = useGetFilteredDataFromService(service);
  const { itemsList, rolesList } = serviceData;
  const { meta } = itemsList;

  useEffect(() => {
    if (service === 'creator' || service === 'becomeCreator') {
      setTab(true);
    }
    if (service === 'creator') {
      dispatch(actionGetRolesList() as any);
    }
  }, [service]);

  useEffect(() => {
    if (service === 'creator') {
      if (rolesList) {
        const getCreator = rolesList?.items?.find((item: any) => item.name === 'CREATOR');

        setCreatorId(getCreator?.id);
      }
    }
  }, [rolesList]);

  useEffect(() => {
    if (creatorId) {
      setFilters({ ...filters, roleId: creatorId });
    }
  }, [creatorId]);

  useEffect(() => {
    setFilters({ ...filters, source: sourceTokenPrice });
  }, [sourceTokenPrice]);

  useEffect(() => {
    if (service === 'assistant') {
      setFilters((prevState) =>{
        return { ...prevState, status: 'PREPARED' }
      });
    }
  }, [service]);

  const searchHandle = useCallback(
    (name: string) => {
      const serviceConfig = {
        event: {
          ...filters,
          title: name, // title - searching by title field
          page: 1,
        },
        venue: {
          ...filters,
          name,
          page: 1,
        },
        // becomeCreator: { // not ready at back-end
        //   ...filters,
        //   userId: name,
        //   page: 1,
        // },
        avatar: {
          ...filters,
          name,
          page: 1,
        },
        customAvatar: {
          ...filters,
          name,
          page: 1,
        },
        assistant: {
          status: 'PREPARED',
          ...filters,
          id: name,
          page: 1,
        },
        proposal: {
          ...filters,
          name,
          page: 1,
        },
        location: {
          ...filters,
          name,
        },
        guild: {
          ...filters,
          name,
        },
        earlyAccess: {
          ...filters,
          name,
        },
        tokenPrice: {
          ...filters,
          name,
        },
        bridgeOrder: {
          ...filters,
          name,
        },
        tokenTransactions: {
          ...filters,
          name,
        },
      };

      if (service === 'user') {
        let filter = { ...filters };
        delete filter.email; // searchBy.value[0]
        delete filter.username; // searchBy.value[1] // if add more values use loop

        filter = { ...filter, [`${searchingBy}`]: name };

        setFilters(filter);
      } else if (service === 'assistant') {
        let filter = { ...filters };

        filter = { ...filter, id: name };

        setFilters(filter);
      } else if (service === 'tokenPrice') {
        const filter = { ...filters, source: sourceTokenPrice };

        setFilters(filter);
      } else if (service === 'tokenTransactions') {
        const filter = { ...filters, checkoutId: name };

        setFilters(filter);
      } else {
        setFilters(serviceConfig[service]);
      }
    },
    [filters, searchingBy, service, sourceTokenPrice],
  );

  const paginationHandle = useCallback(
    (newPage: number) => {
      setFilters({
        ...filters,
        page: newPage,
      });
    },
    [filters],
  );

  const isShownSearch = () => {
    switch (service) {
      case 'creator':
      case 'becomeCreator':
      case 'eventRole':
      case 'customAvatar':
      case 'instance':
      case 'pool':
      case 'tokenPrice':
      case 'bridgeOrder':
      case 'earlyAccess':
        return false;
      default:
        return true;
    }
  };

  const hideCreateButton = (serviceName: string) => {
    switch (serviceName) {
      case 'creator':
      case 'becomeCreator':
      case 'avatar':
      case 'proposal':
      case 'location':
      case 'guild':
      case 'earlyAccess':
      case 'assistant':
      case 'tokenTransactions':
      case 'bridgeOrder':
        return false;
      default:
        return true;
    }
  };

  const handleChangeSearchingMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchigBy(event.target.value);
  };

  const handleChangeSourceTokenPriceMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSourceTokenPriceList(event.target.value);
  };

  const handleChangeSearchingRequestStatusMethod = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        status: event.target.value,
      }
    });
  }, []);

  const handleChangeTab = () => {
    setFilters(initialFilter);
  }

  const showCreatorTabs = () =>
    (service === 'creator' || service === 'becomeCreator') && (
      <div className={styles.table__tabs}>
        <Link
          to={`${paths.admin.creators}`}
          className={cn(styles.table__tab, {
            [styles.table__tab__active]: service === tabs.creators,
          })}
          onClick={handleChangeTab}
          color="inherit"
          type="button"
        >
          Creators
        </Link>
        <Link
          to={`${paths.admin.becomeCreator}`}
          className={cn(styles.table__tab, {
            [styles.table__tab__active]: service === tabs.becomeCreator,
          })}
          onClick={handleChangeTab}
          color="inherit"
          type="button"
        >
          Become creator
        </Link>
      </div>
    );

  if (!serviceData.itemsList) return null;

  return (
    <div className={styles.table}>
      {tab && showCreatorTabs()}
      <TableHead>
        <div className={styles.table__search_wrap}>
        {isShownSearch() && (
          <SectionSearch
            className={styles.table__search}
            searchHandle={searchHandle}
          />
        )}
        {service === 'user' && (
          <TextField
            id="select-search-type"
            className={styles.table__select}
            select
            label="Search by"
            value={searchingBy}
            onChange={handleChangeSearchingMethod}
          >
            {searchBy.map((option: any) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {service === 'tokenPrice' && (
          <TextField
            id="select-search-type"
            className={styles.table__select}
            select
            label="Source"
            value={sourceTokenPrice}
            onChange={handleChangeSourceTokenPriceMethod}
          >
            {sourceTokenPriceList.map((option: any) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        {service === 'assistant' && filters.status && (
          <TextField
            id="select-assistant-status"
            className={styles.table__select}
            select
            label="Filter by status"
            value={filters.status}
            onChange={handleChangeSearchingRequestStatusMethod}
          >
            {knowledgeBaseRequestStatusOptions.map((option: any) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        </div>
        {hideCreateButton(service) && (
          !(service === 'tokenPrice' && !hasRole) && (
            <Link
              to={`/${moduleName}/create`}
              className={styles.table__link}
            >
              Create new {capitalizeAllText(correctServiceName(service))}
            </Link>
          )
        )}
      </TableHead>
      <TableBody
        filters={filters}
        setFilters={setFilters}
        serviceData={serviceData}
      />
      <Pagination
        meta={meta}
        setNewPage={paginationHandle}
        activePage={filters.page}
      />
    </div>
  );
};
